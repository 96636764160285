@import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
* {
  box-sizing: border-box;
  font-family: 'Montserrat', Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.03em;
}

body {
  margin: 0;
  font-family: 'Montserrat', Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html, body, #root {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  margin: 0;
}

#root {
  box-sizing: border-box;
  background-color: #0a0e18;
  background-color: #39383e;
  height: 100%;
  width: 100%;
}

.app {
  box-sizing: border-box;
  min-width: 100%;
  min-height: 100%;
  margin: 0;
  position: absolute;
  background-color: #39383e;
  display: flex;
}

.app .version {
  display: block;
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 70%;
  color: #0a0e18;
}

.wrap {
  box-sizing: border-box;
  width: 80%;
  max-width: 1200px;
  background-color: white;
  border-radius: 2px;
  box-shadow: 0 3px 5px rgba(0,0,0,0.3);
  margin: 2em auto;
  padding: 11em 2em 2em 2em;
  /* flex-grow: none; */
  align-self: flex-start;

  background-image: url(/static/media/header.52cc940c.png);
  background-repeat: no-repeat;
  background-position: top left;
}

.request {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 -15px;
  flex: 0 1 auto;
}

.request-form,
.message-thanks {
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  flex: 2 0;
}

.message-thanks p {
  line-height: 1.8em;
}

.trellocards {
  max-height: 900px;
  margin: 0 15px;
  flex: 1 0;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 720px) {
  .request {
    flex-direction: column;
  }

  .trellocards {
    margin-top: 3em;
    padding-top: 1em;
    border-top: 1px solid #a9a6a9;
  }
}

.trellocards-cards {
  overflow-x: hidden;
  overflow-y: auto;
}

.trellocards h1 span {
  font-weight: 400;
  font-size: 70%;
}

option.select-placeholder {
  color: red;
}


.invalid-field,
.invalid-field--date {
  margin: 0.5em 0;
  font-size: 75%;
  color: #a91920;
}

.wasSubmitted input:invalid ~ .invalid-field,
.wasSubmitted textarea:invalid ~ .invalid-field,
.wasSubmitted select:invalid ~ .invalid-field,
.wasSubmitted .invalid-field--date {
  display: block;
}

.invalid-field,
.invalid-field--date,
.valid-field--date {
  display: none;
}
.formgroup {
  margin: 1em 0;
}

label {
  font-size: 70%;
  font-weight: 700;
  display: block;
  margin: 0 0 0.4em 0;
}

.formcontrol,
.DayPickerInput input {
  display: block;
  font-size: 100%;
  padding: 0.5em;
  border: 1px solid #a9a6a9;
  width: 100%;
}
.DayPickerInput {
  display: block !important;
}
.DayPickerInput-Overlay {
  z-index: 2 !important;
}


select {
  -webkit-appearance: none;
  border-radius: 0;
  background-color: white;
  position: relative;
}
.select {
  position: relative;
}
.select::after {
  content: "";
  display: block;
  position: absolute;
  top: 15px;
  right: 15px;
  pointer-events: none;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #a9a6a9;
}

/* .DayPicker-Day {
  color: #42ba85;
}
.DayPicker-Day.DayPicker-Day--disabled {
  color: #000000;
} */

.DayPicker-Day.DayPicker-Day--leaddays {
  color: #e3c0c0;
}

.ck-editor__editable_inline {
  min-height: 20em;
  line-height: 1.7em;
}

button,
a.button {
  font-size: 110%;
  display:inline-block;
  padding:0.35em 1.1em;
  border:0.1em solid #79256f;
  margin:0 0.3em 0.3em 0;
  border-radius:0.12em;
  box-sizing: border-box;
  text-decoration:none;
  color:#79256f;
  text-align:center;
  transition: all 0.2s;
}
button:hover,
a.button:hover {
  color:#fff;
  background-color:#79256f;
}

@media all and (max-width:30em) {
  button,
  a.button {
    display:block;
    margin:0.4em auto;
  }
}





.trellocards-card {
  padding: 10px 0;
  border-bottom: 1px solid #efefef;
}

.trellocards-cardtitle {
  font-size: 110%;
  margin: 0;
  font-weight: 700;
  letter-spacing: -0.03em;
}

.trellocards-cardmeta {
  display: flex;
  flex-direction: row;
}

.trellocards-carddept {
  display: flex;
  flex-direction: row;
  align-self: center;
  border-radius: 5px;
  padding: 0.1em 0.4em;
  margin: 0 0.3em 0 0;
  font-size: 70%;
  font-weight: 700;
  color: #fff;
  background-color: #a9a6a9;
}

.trellocards-carddept__green { background-color: #61bd4f; }
.trellocards-carddept__orange { background-color: #ffab4a; }
.trellocards-carddept__yellow { background-color: #f2d600; }
.trellocards-carddept__red { background-color: #eb5a46; }
.trellocards-carddept__purple { background-color: #c377e0; }
.trellocards-carddept__blue { background-color: #0079bf; }
.trellocards-carddept__sky { background-color: #00c2e0; }
.trellocards-carddept__lime { background-color: #51e898; }
.trellocards-carddept__pink { background-color: #ff80ce; }
.trellocards-carddept__black { background-color: #111111; }

.trellocards-duedate {
  display: flex;
  flex-direction: row;
  align-self: center;
  border-radius: 5px;
  padding: 0.2em 0;
  font-size: 70%;
  font-weight: 400;
  color: #a9a6a9;
  background-color: none;
  flex: 1 0;
}

.trellocards-cardmember {
  align-self: end;
  margin-left: 5px;
}

.trellocards-cardmember {
  width: 30px;
  height: 30px;
  border-radius: 50px;;
}

.trellocards-description {
  display: none;
}

input:invalid,
select:invalid {
  border: 3px solid red;
}
